<template>
    <div>
        <nav>
            <div>
                <router-link to="/"><i class="fal fa-arrow-left"></i> Назад</router-link>
            </div>
            <h4>Профиль</h4>
            <div class="delete error" @click="deleteProfile()">
                <i class="far fa-trash"></i> Удалить
            </div>
        </nav>
        <form class="settings">
            <label for="">Ваш email: </label>
            <input type="text" autocomplete="new-password" placeholder="email" v-model="email" />

            <label for="">Часовой пояс: </label>
            <select disabled>
                <option selected>{{ timeZone }}</option>
            </select>

            <label for="">Основная валюта: </label>
            <v-select
                :items="getCurrencyItems"
                :menu-props="{ offsetY: true, maxHeight: '50vh' }"
                label="Валюта"
                class="select"
                v-model="currency"
                solo
                flat
                rounded
            >
                <template v-slot:selection="{ item }" >
                    <v-list-item-content>
                        <v-list-item-title class="currency">
                            <i v-if="item.sign" :class="item.sign"></i><span v-else>{{ item.alias }}</span> {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <template v-slot:item="{ item }" >
                    <v-list-item-content>
                        <v-list-item-title class="currency">
                            <i v-if="item.sign" :class="item.sign"></i><span v-else>{{ item.alias }}</span> {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-select>

            <label class="settings__category-collapse">Свернуть категории?</label>
            <v-radio-group v-model="isDashOpened" row class="fieldset settings__category-collapse">
                <v-radio label="Если больше 4-х" class="flex-grow-1" :value="false"></v-radio>
                <v-radio class="flex-grow-1" label="Не сворачивать" :value="true"></v-radio>
            </v-radio-group>

            <label>Округлять копейки?</label>
            <v-radio-group v-model="isRound" row class="fieldset">
                <v-radio label="Нет" class="flex-grow-1" :value="false"></v-radio>
                <v-radio class="flex-grow-1" label="Округлять" :value="true"></v-radio>
            </v-radio-group>

            <label>После добавления операции:</label>
            <v-radio-group v-model="clearFormAfterAdding" row class="fieldset">
                <v-radio class="flex-grow-1" label="Очищать форму" :value="true"></v-radio>
                <v-radio label="Не очищать" class="flex-grow-1" :value="false"></v-radio>
            </v-radio-group>

            <label class="devider"></label>

            <span class="submit">
                <label for="">Новый пароль: </label>
                <input type="password" autocomplete="new-password" placeholder="(минимум 6 символов)" v-model="newPass" />

                <label for="">Повторите пароль: </label>
                <input type="password" autocomplete="new-password" placeholder="Повторите пароль" v-model="newPass2" />
            </span>

            <label for="">Старый пароль: </label>
            <input type="password" autocomplete="new-password" placeholder="Введите старый пароль" v-model="oldPass" />

            <span class="submit">
                <spinner v-if="loading"/>
                <input @click.prevent="formSubmited()" class="main" type="submit" value="Сохранить">
            </span>
        </form>

        <p v-if="!isValid" class="message error">
            <span>{{ errorMsg || 'Пожалуйста проверьте, корректно ли заполнены все поля!' }}</span>
            <i class="far fa-exclamation-circle"></i>
        </p>
    </div>
</template>

<script>
import $ from 'jquery';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'profile',
    components: {
    },
    data() {
        return {
            email: '',
            currency: 'USD',
            timeZone: '',
            newPass: '',
            newPass2: '',
            oldPass: '',
            isValid: true,
            errorMsg: '',
            loading: false,
            isRound: false,
            isDashOpened: false,
            clearFormAfterAdding: false,
        }
    },
    mounted() {
        this.email = this.currentUser.email;
        this.currency = this.currentUser.currency;
        this.timeZone = this.currentUser.timeZone;
        this.isRound = this.currentUser.settings?.isRound;
        this.isDashOpened = this.currentUser.settings?.isDashOpened;
        this.clearFormAfterAdding = this.currentUser.settings?.clearFormAfterAdding;
    },
    computed: {
        ...mapGetters(["currentUser","getCurrencyItems"]),
    },
    methods: {
        ...mapActions(["updateUser", "deleteUser", "loadExchangeRates"]),
        async formSubmited() {
            this.loading = true;
            this.isValid = true;
            this.errorMsg = '';

            // Validation
            let checkEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( !checkEmail.test(String(this.email).toLowerCase()) ) {
                this.isValid = false;
                this.loading = false;
                return 0;
            }

            let newUserDate = {
                email: this.email,
                timeZone: this.timeZone,
                currency: this.currency,
                settings: {
                    isRound: this.isRound,
                    isDashOpened: this.isDashOpened,
                    clearFormAfterAdding: this.clearFormAfterAdding
                }
            };

            if ( this.newPass || this.newPass2 ) {
                if ( this.newPass !== this.newPass2 || this.newPass.length < 6 || this.oldPass.length < 6 ) {
                    this.isValid = false;
                    this.loading = false;
                    return 0;
                }
                newUserDate.password = this.newPass;
                newUserDate.oldPass = this.oldPass;
            }

            try {
                await this.updateUser(newUserDate);
                await this.loadExchangeRates();
                this.$router.go(-1);
            } catch (error) {
                this.isValid = false;
                this.errorMsg = error;
            }

            this.loading = false;
        },
        deleteProfile() {
            this.$showConfirmDialog({
                title: 'Вы действительно хотите удалить Ваш аккаунт?',
                description: 'Вся информация о счетах и операциях будет удалена!',
                approvedCallback: async () => {
                    try {
                        await this.deleteUser();
                        this.$router.go();
                    } catch (error) {
                        this.isValid = false;
                        this.errorMsg = error;
                    }
                }
            });
        }
    },
    watch: {
        isValid: function (value) {
            if (value) return;
            $('html, body').animate({
                scrollTop: $(document).scrollTop() + 70,
            }, 300);
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/forms.scss';

    nav {
        border-bottom: $border;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    a {
        display: inline-flex;
        align-items: center;
        color: $text;
        text-transform: uppercase;
        font-size: 20px;
        padding: 13px 0px;
        &:hover {
            text-decoration: none;
        }
        i {
            margin-right: 15px;
        }
    }

    h4 {
        display: inline-block;
        font-size: 20px;
        color: $text;
        text-transform: uppercase;
        padding: 11px 0;
    }

    .delete {
        font-size: 20px;
        cursor: pointer;
        i {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 1024px) {
        a {
            font-size: 16px;
            padding: 11px 0px;
            i {
                margin-right: 12px;
            }
            &.edit {
                font-size: 15px;
            }
        }
        h4, .delete {
            font-size: 18px;
            padding: 10px 0;
            i {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width: 610px) {
        nav {
            padding: 0 15px;
            .mx-datepicker {
                width: 30px !important;
            }
        }
        .settings__category-collapse {
            display: none;
        }
    }
</style>
<style lang="scss">
    .v-list-item__title.currency i {
        font-size: 14px;
    }
</style>
